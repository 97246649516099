export const products = [
    {
        id: 1,
        img: "https://img.freepik.com/free-vector/portrait-beautiful-girl-vector-cartoon-illustration_1196-946.jpg",
        link: "https://xanderbilla.vercel.app"
    },
    {
        id: 2,
        img: "https://img.freepik.com/free-vector/portrait-beautiful-girl-vector-cartoon-illustration_1196-946.jpg",
        link: "https://xanderbilla.vercel.app"
    },
    {
        id: 3,
        img: "https://img.freepik.com/free-vector/portrait-beautiful-girl-vector-cartoon-illustration_1196-946.jpg",
        link: "https://xanderbilla.vercel.app"
    },
    {
        id: 4,
        img: "https://img.freepik.com/free-vector/portrait-beautiful-girl-vector-cartoon-illustration_1196-946.jpg",
        link: "https://xanderbilla.vercel.app"
    },
    {
        id: 5,
        img: "https://img.freepik.com/free-vector/portrait-beautiful-girl-vector-cartoon-illustration_1196-946.jpg",
        link: "https://xanderbilla.vercel.app"
    },
    {
        id: 6,
        img: "https://img.freepik.com/free-vector/portrait-beautiful-girl-vector-cartoon-illustration_1196-946.jpg",
        link: "https://xanderbilla.vercel.app"
    },
]  